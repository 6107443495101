import { BaseEntity } from './BaseEntity';
import { IdentifierType } from './IdentifierType';
import { User } from './User';

export interface UserIdentifier extends BaseEntity {
  TypeId: number;
  UserId: number;
  Value: string;
  IsActive: boolean;
  Confirmed: boolean;
  ConfirmDate: Date;
  Type: IdentifierType;
  User: User;
}

export enum UserIdentifierTypeIds {
  Email = 1,
  Phone = 2,
  Telegram = 3,
  Vk = 4,
  Fb = 5
}
